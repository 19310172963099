<template>
    <section v-loading="loading">
        <div
        class="position-relative"
        :class="$route.name == 'admin.tiendas.produccion.metricas' ? 'custom-scroll overflow-auto height-calc' : $route.name == 'tendero.metricas' ? 'height-tendero box custom-scroll overflow-auto border mt-2 mx-3 br-t-12' : 'height-leechero mx-3 custom-scroll overflow-auto'"
        >
            <timercomponent v-model="tipo_fecha" @refresh="montar()" />

            <div class="row mx-3 px-3 mt-3 py-3 bg-white br-t-12 f-18 f-600 text-general">
                Relación de ventas con {{ $config.cliente + 's' }}
            </div>
            <div class="row mx-3 bg-white br-b-12 px-4 mb-3">
                <cargando v-if="cargandoComparativos" />
                <div class="px-3 m-3">
                    <div class="row cr-pointer" :class="filtro_relacion==4?'text-general':''" @click="filtro_relacion = 4">
                        <p class="p-2">Hoy</p>
                        <div class="pt-1 w-100" :class="filtro_relacion==4?'border-b-black':'bg-whitesmoke'" />
                    </div>
                    <div class="row cr-pointer" :class="filtro_relacion==1?'text-general':''" @click="filtro_relacion = 1">
                        <p class="p-2">Última semana</p>
                        <div class="pt-1 w-100" :class="filtro_relacion==1?'border-b-black':'bg-whitesmoke'" />
                    </div>
                    <div class="row cr-pointer" :class="filtro_relacion==2?'text-general':''" @click="filtro_relacion = 2">
                        <p class="p-2">Último mes</p>
                        <div class="pt-1 w-100" :class="filtro_relacion==2?'border-b-black':'bg-whitesmoke'" />
                    </div>
                </div>
                <div v-for="(rel, idx) in relaciones" :key="idx" class="br-12 m-2" :class="idx>1 && rel.ids.length > 0 ? 'cr-pointer' : ''" @click="modalRelacion(idx,rel.ids,rel.nombre)">
                    <div
                    class="br-12 p-2 text text-2 text-center px-4 text-general"
                    style="width:232px;height:42px;background-color:#F6F9FB;"
                    >
                        {{ rel.nombre }}
                    </div>
                    <div
                    class="row mx-0 my-1 px-2"
                    :class="{ 'text-general':(rel.porcentaje>0), 'text-danger':(rel.porcentaje<0), '':(rel.porcentaje==0) }"
                    >
                        <i :class="{ 'icon-up':(rel.porcentaje>0), 'icon-down':(rel.porcentaje<0), '':(rel.porcentaje==0) }" />
                        <span>{{ rel.porcentaje>0?'+':'' }}{{ rel.porcentaje }}%</span>
                    </div>
                    <div class="row mx-0 my-1 f-22 px-2 text-muted">
                        {{ idx == 1 || idx == 2 ? separadorNumero(rel.valor,1) : agregarSeparadoresNumero(rel.valor) }}
                    </div>
                </div>
            </div>
            <!-- Graficas -->
            <graficas ref="grafica" :tipo-fecha="tipo_fecha" />
            <!-- Graficas -->
            <div class="row align-items-center mx-0 px-xl-5 px-lg-2 px-md-0 px-sm-0 justify-content-xl-start justify-content-lg-start justify-content-md-center justify-content-sm-center justify-content-center">
                <div class="col-auto pl-0 d-middle text-general f-15">
                    Rango de análisis
                </div>
                <div class="col-xl-2 col-lg-2 col-md col-sm col-10 my-xl-0 my-lg-0 my-md-2 my-sm-2 my-1 px-0">
                    <el-select v-model="rango" placeholder="Seleccionar" size="small" class="w-100">
                        <el-option
                        v-for="item in rangos"
                        :key="item.id"
                        :label="item.nombre"
                        :value="item.id"
                        />
                    </el-select>
                </div>
                <div class="col-xl-3 col-lg-4 col-md col-sm col-10 my-xl-0 my-lg-0 my-md-2 my-sm-2 my-1 px-0 mx-2">
                    <div class="date-picker-edit">
                        <el-date-picker
                        v-model="fecha"
                        class="w-100"
                        size="small"
                        format="dd-MM-yyyy"
                        value-format="yyyy-MM-dd"
                        :disabled="disable_fecha"
                        popper-class="date-picker-edit-popper"
                        type="daterange"
                        start-placeholder="Fecha inicio"
                        end-placeholder="Fecha fin"
                        />
                    </div>
                </div>
                <div class="col-auto my-xl-3 my-lg-2 my-md-2 my-sm-2">
                    <div class="btn-general f-14 px-4" style="height:32px;" @click="informes">
                        Mostrar informe
                    </div>
                </div>
                <cargando v-if="cargandoMetricas" mini />
            </div>

            <div class="row mx-3 justify-content-xl-around my-3 bg-white br-12 py-3">
                <p class="col-12 text-general f-18 f-600 mb-4">Relación de ventas con {{ $config.cliente }}</p>
                <div class="card-cantidades text-center">
                    <div class="title shadow px-3 py-2 br-12 text-general mx-2 mr-3" style="background-color:#F6F9FB;">
                        Cantidad de pedidos
                    </div>
                    <span class="f-25 text-general2">
                        {{ agregarSeparadoresNumero(comparativas.cantidad) }}
                    </span>
                </div>
                <div class="card-cantidades text-center">
                    <div class="title shadow px-3 py-2 br-12 text-general mx-2 mr-3" style="background-color:#F6F9FB;">
                        Ticket promedio
                    </div>
                    <span class="f-25 text-general2">
                        {{ separadorNumero(comparativas.promedio) }}
                    </span>
                </div>
                <div class="card-cantidades text-center">
                    <div class="title shadow px-3 py-2 br-12 text-general mx-2 mr-3" style="background-color:#F6F9FB;">
                        Total {{ $config.vendedor }}
                    </div>
                    <span class="f-25 text-general2">
                        {{ agregarSeparadoresNumero(comparativas.leecheros) }}
                    </span>
                </div>
                <div class="card-cantidades text-center">
                    <div class="title shadow px-3 py-2 br-12 text-general mx-2 mr-3" style="background-color:#F6F9FB;">
                        Valor total
                    </div>
                    <span class="f-25 text-general2">
                        {{ separadorNumero(comparativas.total) }}
                    </span>
                </div>
            </div>

            <div class="row mx-3 py-3 mt-4 bg-white br-t-12">
                <div class="col-xl-3 col-lg-3 col-md-auto col-sm-auto px-4 text-general f-18 f-600">
                    Ventas Promedio hora/semana
                </div>
                <div class="col-xl col-lg-10 col-md-10 ml-xl-0 ml-lg-auto ml-md-auto ml-sm-auto">
                    <div class="row mx-5 justify-tipo-s1rt border-bottom">
                        <div v-for="(data, i) in tipos" :key="i" class="col-auto cr-pointer p-2 f-14" :class="tipo == data.id ? 'border-b-red text-gr-red' : 'text-muted2' " @click=" tipo = data.id">
                            {{ data.mes }}
                        </div>
                    </div>
                </div>
            </div>
            <!-- Chart Promedio -->
            <chartBarras :datos="datos_promedio" :chart="chart_promedio" />
            <!-- Chart Promedio -->
            <div class="row f-18 f-600 px-3 mx-3 mt-4 bg-white py-3 br-t-12 text-general">
                Productos que han generado mayores ingresos
                <div v-if="id_cedis != 0" class="bg-light-f5 f-500 br-20 text-general2 px-2 ml-auto">
                    <i class="icon-package-variant text-general2 f-20" />
                    Stock actual
                </div>
            </div>
            <div class="row mx-3 px-5 py-4 br-b-12 bg-white" :class="!productos_card_total.length ? 'justify-center' : ''">
                <template v-if="productos_card_total.length">
                    <div v-for="(producto,d) in productos_card_total" :key="d" class="col-auto position-relative">
                        <el-tooltip v-if="producto.estado == 0" placement="bottom" content="Inactivo" effect="light">
                            <div class="position-absolute z-1 rounded-circle bg-icon d-middle-center" style="right:20px;">
                                <i class="icon-eye-off f-25 text-white" />
                            </div>
                        </el-tooltip>
                        <div class="position-absolute z-1 bg-light-f5 f-15 f-500 br-20 text-general2 ml-auto px-2 py-1" style="right:31px;top:-16px;">
                            <i class="icon-package-variant text-general2 f-15" />{{ producto.stock_actual }}
                        </div>
                        <el-tooltip placement="bottom" :content="`${producto.sku} - ${producto.nombre} - ${producto.presentacion}`" effect="light">
                            <div class="border br-12 d-middle-center" style="width:115px;height:115px;object-fit:cover;">
                                <img class="w-50" :src="producto.foto_firmada" alt="" />
                            </div>
                        </el-tooltip>
                        <p class="mb-0 text-general f-15 text-center">
                            {{ separadorNumero(producto.total,1) }}
                        </p>
                        <p class="mb-0 text-general f-600 f-18 text-center">
                            {{ agregarSeparadoresNumero(producto.porcentaje_total,1) }}%
                        </p>
                    </div>
                </template>
                <!-- Sin datos -->
                <div v-else class="col">
                    <div class="row justify-center">
                        <div class="borde text-center p-2 br-12 sin_datos">
                            <img src="/img/no-imagen/mercado.svg" alt="" />
                        </div>
                    </div>
                    <div class="row justify-center f-15 text-muted2">
                        Aun no se han registrado ventas
                    </div>
                </div>
                <!-- Sin datos -->
                <div v-if="productos_ver_mas_total.total > 0" class="col-auto ml-auto">
                    <div class="br-12 bg-general3 d-middle-center cr-pointer" style="width:115px;height:115px;" @click="AbrirProductos(1)">
                        <p class="mb-0 text-white">
                            Ver más <br /> <i class="icon-angle-right text-white ml-3" />
                        </p>
                    </div>
                    <p class="mb-0 text-general f-15 text-center">
                        {{ agregarSeparadoresNumero(productos_ver_mas_total.total,1) }}
                    </p>
                    <p class="mb-0 text-general f-600 f-18 text-center">
                        {{ agregarSeparadoresNumero(productos_ver_mas_total.porcentaje,1) }}%
                    </p>
                </div>
            </div>
            <div class="row f-18 f-600 px-3 mx-3 bg-white br-t-12 mt-3 py-3 text-general align-items-center">
                Productos presentes en mayor número de pedidos
                <div class="bg-light-f5 f-500 br-20 text-general2 px-2 ml-auto">
                    <i class="icon-package-variant text-general2 f-20" />
                    Stock actual
                </div>
            </div>
            <div class="row mx-3 bg-white br-b-12 px-3 py-3 mb-3" :class="!productos_card_cantidad.length ? 'justify-center' : ''">
                <template v-if="productos_card_cantidad.length">
                    <div v-for="(producto,d) in productos_card_cantidad" :key="d" class="col-auto position-relative">
                        <el-tooltip v-if="producto.estado == 0" placement="bottom" content="Inactivo" effect="light">
                            <div class="position-absolute z-1 rounded-circle bg-dark d-middle-center" style="right:20px;width:40px;height:40px;">
                                <i class="icon-eye-off f-25 text-white" />
                            </div>
                        </el-tooltip>
                        <div class="position-absolute z-1 bg-light-f5 f-15 f-500 br-20 text-general2 ml-auto px-2 py-1" style="right:31px;top:-16px;">
                            <i class="icon-package-variant text-general2 f-15" />{{ producto.stock_actual }}
                        </div>
                        <el-tooltip placement="bottom" :content="`${producto.sku} - ${producto.nombre} - ${producto.presentacion}`" effect="light">
                            <div class="border br-12 d-middle-center" style="width:115px;height:115px;object-fit:cover;">
                                <img class="w-50" :src="producto.foto_firmada" alt="" />
                            </div>
                        </el-tooltip>
                        <p class="mb-0 text-general f-15 text-center">
                            {{ agregarSeparadoresNumero(producto.cantidad,1) }}
                        </p>
                        <p class="mb-0 text-general f-600 f-18 text-center">
                            {{ agregarSeparadoresNumero(producto.porcentaje_cantidad,1) }}%
                        </p>
                    </div>
                </template>
                <!-- Sin datos -->
                <div v-else class="col">
                    <div class="row justify-center">
                        <div class="borde text-center p-2 br-12 sin_datos">
                            <img src="/img/no-imagen/mercado.svg" alt="" />
                        </div>
                    </div>
                    <div class="row justify-center f-15 text-muted2">
                        Aun no se han registrado ventas
                    </div>
                </div>
                <!-- Sin datos -->
                <div v-if="productos_ver_mas_cantidad.cantidad > 0" class="col-auto ml-auto">
                    <div class="br-12 bg-general3 d-middle-center cr-pointer" style="width:115px;height:115px;" @click="AbrirProductos(2)">
                        <p class="mb-0 text-white">
                            Ver más <br /> <i class="icon-angle-right text-white ml-3" />
                        </p>
                    </div>
                    <p class="mb-0 text-general f-15 text-center">
                        {{ agregarSeparadoresNumero(productos_ver_mas_cantidad.cantidad,1) }}
                    </p>
                    <p class="mb-0 text-general f-600 f-18 text-center">
                        {{ agregarSeparadoresNumero(productos_ver_mas_cantidad.porcentaje,1) }}%
                    </p>
                </div>
            </div>
            <div class="row mx-3 px-3 py-3 my-3 bg-white br-12">
                <div class="col-12">
                    <p class="text-general f-18 f-600">
                        Categorias mas vendidas
                    </p>
                </div>
                <div class="col px-5 py-3 mb-5">
                    <div class="row mx-0">
                        <div v-for="(data, d) in categorias_dinero.slice(0,8)" :key="d" class="col-auto px-2">
                            <div class="br-12 border d-middle-center px-3 f-12" style="width:125px;height:75px;background-color:#F8F9FF;">
                                {{ data.nombre }}
                            </div>
                            <p class="text-center my-2">
                                {{ separadorNumero(data.total) }}
                            </p>
                            <p class="text-center text-gr-general f-600 my-2">
                                {{ agregarSeparadoresNumero(data.porcentaje_total,1) }} %
                            </p>
                        </div>
                    </div>
                </div>
                <div v-if="categorias_dinero.length > 8" class="col-auto mt-4">
                    <div
                    class="border bg-general3 text-white f-18 br-12 px-4 text-center py-2 cr-pointer"
                    @click="verCategorias('Categorias mas vendidas')"
                    >
                        Ver Más <br /> <i class="icon-angle-right" />
                    </div>
                </div>
            </div>
            <div class="row px-3 bg-white br-t-12 py-3 mx-3 mt-3 f-18 f-600 text-general">
                Pedidos recibidos de acuerdo a la distancia
            </div>
            <!-- Grafica Pedidos Recibidos de acuerdo a la distancia -->
            <chartBarras :datos="datos_distancia" :chart="chart_distancia" />
            <!-- Grafica Pedidos Recibidos de acuerdo a la distancia -->
            <!-- Compradores por genero -->
            <div class="row mx-3 bg-white br-t-12 px-3 py-3 justify-content-xl-start justify-content-lg-start justify-content-md-start justify-content-sm-center justify-content-center mt-5 text-general f-600 f-18">
                Compradores por género
            </div>
            <div class="row mx-3 bg-white px-xl-5 px-lg-5 px-md-5 px-sm-12 px-0">
                <div class="col">
                    <div class="row mx-0 br-5" style="height:30px;background-color:#EBEBEB;">
                        <template v-if="datos == true">
                            <el-tooltip placement="top" :content="`${generos[1].nombre}: ${agregarSeparadoresNumero(generos[1].porcentaje)}%`" effect="light">
                                <div class="h-100 bg-general3 br-tl-5 br-bl-5" :style="`width:${generos[1].porcentaje}%;`" />
                            </el-tooltip>
                            <el-tooltip placement="top" :content="`${generos[0].nombre}: ${agregarSeparadoresNumero(generos[0].porcentaje)}%`" effect="light">
                                <div class="h-100 bg-general2" :style="`width:${generos[0].porcentaje}%;`" />
                            </el-tooltip>
                            <el-tooltip placement="top" :content="`${generos[2].nombre}: ${agregarSeparadoresNumero(generos[2].porcentaje)}%`" effect="light">
                                <div class="h-100 bg-gris br-tr-5 br-br-5" :style="`width:${generos[2].porcentaje}%;`" />
                            </el-tooltip>
                        </template>
                    </div>
                </div>
            </div>
            <div class="row mx-3 py-3 container-generos bg-white br-b-12 justify-content-center">
                <div class="mujer col-xl-auto col-lg-auto col-md-6 col-sm-auto my-xl-0 my-lg-0 my-md-2 my-sm-2 my-2">
                    <div class="border br-12 d-middle-center" style="width:273px;height:95px;">
                        <img src="/img/icons/person (1).svg" style="width:50px;height:65px;object-fit:cover;" alt="" />
                        <div class="px-2">
                            <p class="mb-0 f-18 text-general f-600">
                                Mujer {{ agregarSeparadoresNumero(generos[1].porcentaje,2) }}%
                            </p>
                            <div class="row mx-0">
                                <div class="col-auto text-general2 f-14 px-2">
                                    {{ separadorNumero(generos[1].total) }}
                                </div>
                                <div class="col-auto text-general2 f-14 px-2">
                                    {{ agregarSeparadoresNumero(generos[1].cantidad) }} Pedidos
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="hombre col-xl-auto col-lg-auto col-md-6 col-sm-auto my-xl-0 my-lg-0 my-md-2 my-sm-2 my-2">
                    <div class="border br-12 d-middle-center" style="width:273px;height:95px;">
                        <img src="/img/icons/person (2).svg" style="width:50px;height:65px;object-fit:cover;" alt="" />
                        <div class="px-2">
                            <p class="mb-0 f-18 text-general f-600">
                                Hombre {{ agregarSeparadoresNumero(generos[0].porcentaje,2) }}%
                            </p>
                            <div class="row mx-0">
                                <div class="col-auto text-general2 f-14 px-2">
                                    {{ separadorNumero(generos[0].total) }}
                                </div>
                                <div class="col-auto text-general2 f-14 px-2">
                                    {{ agregarSeparadoresNumero(generos[0].cantidad) }} Pedidos
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="otro col-xl-auto col-lg-auto col-md-6 col-sm-auto my-xl-0 my-lg-0 my-md-2 my-sm-2 my-2">
                    <div class="border br-12 d-middle-center" style="width:273px;height:95px;">
                        <img src="/img/icons/astronaut (1).svg" style="width:50px;height:65px;object-fit:cover;" alt="" />
                        <div class="px-2">
                            <p class="mb-0 f-18 text-general f-600">
                                Otro {{ agregarSeparadoresNumero(generos[2].porcentaje,2) }}%
                            </p>
                            <div class="row mx-0">
                                <div class="col-auto text-general2 f-14 px-2">
                                    {{ separadorNumero(generos[2].total) }}
                                </div>
                                <div class="col-auto text-general2 f-14 px-2">
                                    {{ agregarSeparadoresNumero(generos[2].cantidad) }} Pedidos
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Compradores por genero -->
            <div class="row mx-3 bg-white br-t-12 py-3 px-xl-5 px-lg-5 px-md-5 px-sm-12 px-0 justify-content-xl-start justify-content-lg-start justify-content-md-start justify-content-sm-center justify-content-center mt-3 text-general f-600 f-18">
                Compradores por edad
            </div>
            <div class="row mx-3 bg-white px-xl-5 px-lg-5 px-md-5 px-sm-12 px-0">
                <div class="col">
                    <div class="row mx-0 br-5" style="height:30px;background-color:#EBEBEB;">
                        <el-tooltip v-for="(data, i) in compradores_edad" :key="i" placement="top" :content="`${data.edad}: ${separadorNumero(data.total)}`" effect="light">
                            <div v-if="data.estado != 11" class="h-100 br-tl-5 br-bl-5 br-tr-5 br-br-5" :class="backgroundEdad(data.estado)" :style="`width:${data.porcentaje}%;`" />
                            <div v-else class="h-100 br-tl-5 br-bl-5 br-tr-5 br-br-5 bg-dark" :style="`width:${data.porcentaje}%;`" />
                        </el-tooltip>
                    </div>
                </div>
            </div>
            <div class="row mx-3 bg-white br-b-12 px-5 py-3 justify-center">
                <div v-for="(data, i) in compradores_edad" :key="i" class="col-xl-2 col-lg-2 col-md-5 col-sm-4 px-2 my-2 text-muted2" style="min-height:95px;">
                    <div class="w-100 h-100 border br-12 px-3">
                        <p class="f-17 tres-puntos py-1">
                            <span class="f-600" :class="estiloEdad(data.estado)"> {{ data.edad }}:   {{ data.porcentaje }}% </span>
                        </p>
                        <p class="f-14 tres-puntos py-1">
                            {{ separadorNumero(data.total) }}
                        </p>
                        <p class="f-14 tres-puntos py-1">
                            {{ agregarSeparadoresNumero(data.cantidad) }} Pedidos
                        </p>
                    </div>
                </div>
            </div>
            <!-- Compras por genero -->
            <div class="row mx-3 bg-white br-t-12 mt-4 pt-3 px-xl-5 px-lg-5 px-md-5 px-sm-12 px-0 justify-content-xl-start justify-content-lg-start justify-content-md-start justify-content-sm-center justify-content-center text-general f-600 f-18">
                Compradores por dispositivo
            </div>
            <div class="row mx-3 bg-white py-3 px-xl-5 px-lg-5 px-md-5 px-sm-12 px-0">
                <div class="col">
                    <div class="row mx-0 br-5" style="height:30px;background-color:#EBEBEB;">
                        <template v-if="datos == true">
                            <el-tooltip placement="top" :content="`${dispositivos[1].nombre}: ${agregarSeparadoresNumero(dispositivos[1].porcentaje)}%`" effect="light">
                                <div class="h-100 bg-general3 br-tl-5 br-bl-5" :style="`width:${dispositivos[1].porcentaje}%;`" />
                            </el-tooltip>
                            <el-tooltip placement="top" :content="`${dispositivos[2].nombre}: ${agregarSeparadoresNumero(dispositivos[2].porcentaje)}%`" effect="light">
                                <div class="h-100 bg-gris" :style="`width:${dispositivos[2].porcentaje}%;`" />
                            </el-tooltip>
                            <el-tooltip placement="top" :content="`${dispositivos[0].nombre}: ${agregarSeparadoresNumero(dispositivos[0].porcentaje)}%`" effect="light">
                                <div class="h-100 bg-light-f5 br-tr-5 br-br-5" :style="`width:${dispositivos[0].porcentaje}%;`" />
                            </el-tooltip>
                        </template>
                    </div>
                </div>
            </div>
            <div class="row mx-3 pb-3 br-b-12 container-dispositivo bg-white justify-content-center">
                <div class="android col-xl-auto col-lg-auto col-md-auto col-sm-auto my-xl-1 my-lg-2 my-md-2 my-sm-2 my-2">
                    <div class="border br-12 d-middle-center" style="width:273px;height:95px;">
                        <img src="/img/icons/android.svg" style="width:50px;height:65px;object-fit:cover;" alt="" />
                        <div class="px-2 ">
                            <p class="mb-0 f-18 text-general f-600">
                                Android {{ agregarSeparadoresNumero(dispositivos[1].porcentaje,2) }}%
                            </p>
                            <div class="row mx-0">
                                <div class="col-auto text-general2 f-14 px-2">
                                    {{ separadorNumero(dispositivos[1].total) }}
                                </div>
                                <div class="col-auto text-general2 f-14 px-2">
                                    {{ agregarSeparadoresNumero(dispositivos[1].cantidad) }} Pedidos
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="ios col-xl-auto col-lg-auto col-md-auto col-sm-auto my-xl-1 my-lg-2 my-md-2 my-sm-2 my-2">
                    <div class="border br-12 d-middle-center" style="width:273px;height:95px;">
                        <img src="/img/icons/apple.svg" style="width:50px;height:65px;object-fit:cover;" alt="" />
                        <div class="px-2 ">
                            <p class="mb-0 f-18 text-general f-600">
                                iOS {{ agregarSeparadoresNumero(dispositivos[2].porcentaje,2) }}%
                            </p>
                            <div class="row mx-0">
                                <div class="col-auto text-general2 f-14 px-2">
                                    {{ separadorNumero(dispositivos[2].total) }}
                                </div>
                                <div class="col-auto text-general2 f-14 px-2">
                                    {{ agregarSeparadoresNumero(dispositivos[2].cantidad) }} Pedidos
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="web col-xl-auto col-lg-auto col-md-auto col-sm-auto my-xl-1 my-lg-2 my-md-2 my-sm-2 my-2">
                    <div class="border br-12 d-middle-center" style="width:273px;height:95px;">
                        <img src="/img/icons/world-wide-web.svg" style="max-width:100%;height:65px;object-fit:cover;" alt="" />
                        <div class="px-2 ">
                            <p class="mb-0 f-18 text-general f-600">
                                Web {{ agregarSeparadoresNumero(dispositivos[0].porcentaje,2) }}%
                            </p>
                            <div class="row mx-0">
                                <div class="col-auto text-general2 f-14 px-2">
                                    {{ separadorNumero(dispositivos[0].total) }}
                                </div>
                                <div class="col-auto text-general2 f-14 px-2">
                                    {{ agregarSeparadoresNumero(dispositivos[0].cantidad) }} Pedidos
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Cancelaciones -->
            <div class="row mx-3 py-3 mt-4 px-0 text-general f-18 f-600 bg-white br-t-12 px-xl-5 px-lg-5 px-md-5 px-sm-12 justify-content-xl-start justify-content-lg-start justify-content-md-start justify-content-sm-center justify-content-center ">
                Índice general de cancelación
            </div>
            <div class="row mx-3 pb-3 px-xl-5 px-lg-5 px-md-5 px-sm-12 px-0 mb-3 br-b-12 bg-white">
                <div class="col">
                    <div class="row mx-0 br-5" style="background-color:#EBEBEB;height:30px;">
                        <template v-if="datos == true">
                            <div class="br-tl-5 br-bl-5 bg-blue h-100" :style="`width:${porcentajes_primera_linea.rechazos}%;`" />
                            <div class="bg-blue-clear" :style="`width:${porcentajes_primera_linea.cancelacion_tienda}%;`" />
                            <div class="br-tr-5 br-br-5 bg-orange" :style="`width:${porcentajes_primera_linea.cancelacion_cliente}%;`" />
                            <div class="br-tr-5 br-br-5 bg-general" :style="`width:${porcentajes_primera_linea.cancelacion_admin}%;`" />
                        </template>
                    </div>
                    <div class="row pt-3 pb-2">
                        <div class="col-auto text-muted2 f-15">
                            Cancelación del {{ agregarSeparadoresNumero(porcentajes_primera_linea.cancelaciones) }}%
                        </div>
                        <div class="col-auto ml-auto text-muted2 f-15">
                            Análisis sobre {{ agregarSeparadoresNumero(porcentajes_primera_linea.total) }} pedidos solicitados
                        </div>
                    </div>
                </div>
            </div>
            <!-- Cancelacion -->
            <cancelacion
            :rechazos="rechazos"
            :cancelacion-tienda="cancelacion_tienda"
            :cancelacion-cliente="cancelacion_cliente"
            :cancelacion-admin="cancelacion_admin"
            :porcentajes="porcentajes_segunda_linea"
            />
            <!-- reclamos -->
            <div class="row mx-3 bg-white br-t-12 py-3 text-general f-600 f-18 px-xl-5 px-lg-5 px-md-5 px-sm-12 justify-content-xl-start justify-content-lg-start justify-content-md-start justify-content-sm-center justify-content-center">
                Índice de reclamación
            </div>
            <div class="row mx-3 bg-white br-b-12 py-3 px-xl-5 px-lg-5 px-md-5 px-sm-12 px-0">
                <div class="col">
                    <el-tooltip placement="bottom" effect="light">
                        <div slot="content" style="min-width:150px;">
                            <div class="row mx-0 border-bottom justify-center">
                                Reclamos
                            </div>
                            <div v-for="(reclamo, r) in reclamos" :key="r" class="row mx-2 justify-content-center py-2">
                                <div style="width:14px;height:14px;border-radius:50%;" :style="`backgroundColor:${reclamo.color}`" />
                                <div class="col">
                                    {{ reclamo.motivo }}
                                </div>
                                <div class="col-auto">
                                    {{ agregarSeparadoresNumero(reclamo.porcentaje_t,1) }}%
                                </div>
                            </div>
                        </div>
                        <div class="row mx-0 br-5" style="height:30px;background-color:#EBEBEB;">
                            <template v-if="reclamos.length">
                                <div v-for="(reclamo,r) in reclamos" :key="r" :class="`${r === 0 ? 'br-l-5' : (r === (reclamos.length - 1) ? 'br-r-5' : '')}`" style="" :style="`width:${reclamo.porcentaje_t}%;backgroundColor:${reclamo.color}`" />
                            </template>
                        </div>
                    </el-tooltip>
                    <div class="row py-2">
                        <div class="col-auto text-general">
                            Reclamacion del {{ agregarSeparadoresNumero(porcentajes_reclamos.porcentaje) }}%
                        </div>
                        <div class="col-auto ml-auto text-general">
                            Análisis sobre {{ agregarSeparadoresNumero(porcentajes_reclamos.total) }} pedidos solicitados
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modales -->
        <modal-productos ref="productos" :data="dataPruebaModal" />
        <modal-ver-categorias ref="modalVerCategorias" />
        <modalDetalleRelacion ref="modalDetalleRelacion" />
        <!-- Modales -->
    </section>
</template>
<script>
import { mapGetters } from 'vuex'
import Vue from 'vue'
import echarts from 'echarts'
import moment from 'moment'
Vue.component('echarts',echarts);
let colorGeneral = getComputedStyle(document.documentElement) .getPropertyValue('--color-general')
let colorGeneral2 = getComputedStyle(document.documentElement) .getPropertyValue('--color-db')
import Metricas from "../../services/metricas"
let color = [ '#A78FE2', '#F1BC8D', '#72DCCF', '#F1E1AB', '#8592A4', '#71C1EC', '#D382C7', '#79BB88', '#9FDDF8', '#F171D8', '#7797F1', '#DC7279', '#B78471', '#76A0B8', '#87DC72', ];

export default {
    components: {
        cancelacion : () => import ('./cancelacion'),
        modalProductos: () => import ('./partials/modalProductos'),
        graficas: () => import ('./graficas'),
        modalDetalleRelacion: () => import ('./partials/modalDetalleRelacion')
    },
    data(){
        return{
            cargandoComparativos: true,
            cargandoMetricas: true,
            compradores_edad: [
                { edad: '-20', estado: 1, cantidad: 6, porcentaje: 5, total : 0},
                { edad: '20-25', estado: 2, cantidad: 8 , porcentaje: 5, total : 0},
                { edad: '25-30', estado: 3, cantidad: 4 , porcentaje: 10, total : 0},
                { edad: '30-35', estado: 4, cantidad: 3 , porcentaje: 10, total : 0},
                { edad: '35-40', estado: 5, cantidad: 4 , porcentaje: 10, total : 0},
                { edad: '40-45', estado: 6, cantidad: 14 , porcentaje: 10, total : 0},
                { edad: '45-50', estado: 7, cantidad: 25 , porcentaje: 10, total : 0},
                { edad: '50-55', estado: 8, cantidad: 12 , porcentaje: 10, total : 0},
                { edad: '55-60', estado: 9, cantidad: 12 , porcentaje: 10, total : 0},
                { edad: '+60', estado: 10, cantidad: 12 , porcentaje: 10, total : 0},
                { edad: 'Sin Determinar', estado: 11, cantidad: 12 , porcentaje: 10, total : 0},
            ],
            tipo_fecha: 1,
            filtro_relacion: 1,
            tipo_relacion: 1,
            relaciones: [
                { nombre: 'Cantidad de pedidos', porcentaje: 0, valor: 0, ids: [] },
                { nombre: 'Total ventas', porcentaje: 0, valor: 0, ids: [] },
                { nombre: 'Ticket promedio', porcentaje: 0, valor: 0, ids: [] },
                { nombre: 'Total tenderos', porcentaje: 0, valor: 0, ids: [] },
                { nombre: 'Tenderos nuevos', porcentaje: 0, valor: 0, ids: [] },
                { nombre: 'Tenderos recurrentes', porcentaje: 0, valor: 0, ids: [] },
                { nombre: 'Tenderos perdidos', porcentaje: 0, valor: 0, ids: [] },
            ],
            datos: true,
            loading: false,
            dataPruebaModal: [
                {
                    imagen: 'https://www.alqueria.com.co/wp-content/uploads/2016/06/dummie_fkl_entera_589x589.png',
                    nombre: 'Leche de Frescaleche xD',
                    descripcion: '$20.000',
                    porcentaje: '25'
                },
                {
                    imagen: 'https://www.alqueria.com.co/wp-content/uploads/2016/06/dummie_fkl_entera_589x589.png',
                    nombre: 'Leche de Frescaleche xD 2',
                    descripcion: '$20.000',
                    porcentaje: '25'
                }
            ],
            datos_promedio: [
                { nombre: 'Hora de mayor venta promedio', valor: 0, fecha: 'Ene. 2020', color: 'bg-gr-general', formato: 1 },
                { nombre: 'Hora de menor venta promedio', valor: 0, fecha: 'Oct. 2019', color: 'bg-gr-pink', formato: 1 },
                { nombre: 'Hora de mayor # pedidos promedio', valor: 0, fecha: 'Oct. 2019', color: 'bg-gr-red', formato: 2 },
                { nombre: 'Hora de menor # pedidos promedio', valor: 0, fecha: 'Oct. 2019', color: 'bg-gr-red', formato: 2 }
            ],
            datos_distancia: [
                { nombre: 'Ventas totales', valor: 0, color: 'bg-gr-general', formato: 1 },
                { nombre: 'Rango con mayor # de ventas', valor: 0, fecha: '', color: 'bg-gr-pink', formato: 1 },
                { nombre: 'Pedidos totales', valor: 0, color: 'bg-gr-red', formato: 2 },
                { nombre: 'Rango con mayor # de pedidos', valor: 0, fecha: '',color: 'bg-gr-red', formato: 2 }
            ],
            fecha: [moment().subtract(1, 'week').toDate(),new Date()],
            disable_fecha: true,
            rangos: [
                { id: 1, nombre: "Hoy"},
                { id: 2, nombre: "Ayer"},
                { id: 3, nombre: "Ultima semana"},
                { id: 4, nombre: "Semana anterior"},
                { id: 5, nombre: "Ultimo mes"},
                { id: 6, nombre: "Mes anterior"},
                { id: 7, nombre: "Ultimo año"},
                { id: 8, nombre: "Personalizado"},
            ],
            rango: 3,
            tipo: 1,
            tipos: [
                { mes: 'Cada Hora', id: 1 },
                { mes: 'Cada día', id: 2 }
            ],
            chart_promedio: {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        label: {
                            backgroundColor: '#283b56'
                        }
                    }
                },
                legend: {
                    data:['Pedidos', 'Ventas']
                },
                xAxis: [
                    {
                        type: 'category',
                        boundaryGap: true,
                    },
                    {
                        type: 'category',
                        boundaryGap: true,
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        scale: true,
                        // name: 'Valor',
                    },
                    {
                        type: 'value',
                        scale: true,
                        // name: 'Pedidos',
                    }
                ],
                dataset:{
                    dimensions:['alias', 'total','cantidad'],
                    source: []
                },
                series: [
                    {
                        name: 'Ventas',
                        type: 'bar',
                        // xAxisIndex: 1,
                        yAxisIndex: 1,
                        itemStyle: {
                            color: colorGeneral2
                        },
                    },
                    {
                        name: 'Pedidos',
                        type: 'line',
                        symbol:'circle',
                        symbolSize: 10,
                        itemStyle: {
                            color: colorGeneral
                        },
                    }
                ]
            },
            chart_distancia: {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        label: {
                            backgroundColor: '#283b56'
                        }
                    }
                },
                legend: {
                    data:['Pedidos', 'Ventas']
                },
                xAxis: [
                    {
                        type: 'category',
                        boundaryGap: true,
                    },
                    {
                        type: 'category',
                        boundaryGap: true,
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        scale: true,
                        // name: 'Valor',
                    },
                    {
                        type: 'value',
                        scale: true,
                        // name: 'Pedidos',
                    }
                ],
                dataset:{
                    dimensions:['distancia', 'total','cantidad'],
                    source: []
                },
                series: [
                    {
                        name: 'Ventas',
                        type: 'bar',
                        // xAxisIndex: 1,
                        yAxisIndex: 1,
                        itemStyle: {
                            color: colorGeneral2
                        },
                    },
                    {
                        name: 'Pedidos',
                        type: 'line',
                        symbol:'circle',
                        symbolSize: 10,
                        itemStyle: {
                            color: colorGeneral
                        },
                    }
                ]
            },
            dias: [],
            horas: [],
            min_dias_cantidad: {
                nombre: '',
                cantidad: 0,
                total: 0,
                alias: ''
            },
            max_dias_cantidad: {
                nombre: '',
                cantidad: 0,
                total: 0,
                alias: ''
            },
            max_dias_total: {
                nombre: '',
                cantidad: 0,
                total: 0,
                alias: ''
            },
            min_dias_total: {
                nombre: '',
                cantidad: 0,
                total: 0,
                alias: ''
            },
            max_horas_cantidad: {
                cantidad: 0,
                total: 0,
                alias: ''
            },
            min_horas_cantidad: {
                cantidad: 0,
                total: 0,
                alias: ''
            },
            max_horas_total: {
                cantidad: 0,
                total: 0,
                alias: ''
            },
            min_horas_total: {
                cantidad: 0,
                total: 0,
                alias: ''
            },
            categorias_dinero: [],
            productos_card_total: [ ],
            productos_ver_mas_total: {
                total: 0,
                porcentaje: 0
            },
            productos_card_cantidad: [ ],
            productos_ver_mas_cantidad: {
                total: 0,
                porcentaje: 0
            },
            generos: [
                {
                    nombre: "Femenino",
                    valor: 0,
                    cantidad: 0,
                    total: 0,
                    porcentaje: 0
                },
                {
                    nombre: "Masculino",
                    valor: 0,
                    cantidad: 0,
                    total: 0,
                    porcentaje: 0
                },
                {
                    nombre: "Otro",
                    valor: 0,
                    cantidad: 0,
                    total: 0,
                    porcentaje: 0
                }
            ],
            dispositivos: [
                {
                    nombre: "Android",
                    valor: 0,
                    cantidad: 0,
                    total: 0,
                    porcentaje: 0
                },
                {
                    nombre: "iOS",
                    valor: 0,
                    cantidad: 0,
                    total: 0,
                    porcentaje: 0
                },
                {
                    nombre: "Web",
                    valor: 0,
                    cantidad: 0,
                    total: 0,
                    porcentaje: 0
                }
            ],
            porcentajes_primera_linea: {
                total: 0,
                cancelaciones: 0,
                rechazos: 0,
                cancelacion_tienda: 0,
                cancelacion_cliente: 0
            },
            rechazos: [],
            cancelacion_tienda: [],
            cancelacion_cliente: [],
            cancelacion_admin: [],
            porcentajes_segunda_linea:{
                rechazos: 0,
                cancelacion_tienda: 0,
                cancelacion_cliente: 0,
                cancelacion_admin: 0
            },
            porcentajes_reclamos: {
                total: 0,
                porcentaje: 0
            },
            reclamos: [],
            semana:{},
            ultimo_mes:{},
            ultimo_trimestre:{},
            hoy:{},
            primeraVez: true,
            metricas_created: null,
            metricas_entregas: null,
            comparativas:{
                cantidad: 0,
                promedio: 0,
                leecheros: 0,
                total: 0,
            }
        }
    },
    computed: {
        ...mapGetters({
            id_cedis:'cedis/id_cedis',
            id_moneda:'cedis/id_moneda',
        }),
        id_tienda(){
            let id_tienda = null
            if (this.$route.params.id_tienda){
                id_tienda = this.$route.params.id_tienda
            } else {
                id_tienda = this.$usuario.tienda.id
            }

            return id_tienda
        },
        moneda_rol(){
            let moneda = null
            if (this.$route.params.id_tienda){
                moneda = this.id_moneda
            } else {
                moneda = this.$usuario.tienda.idm_moneda
            }

            return moneda
        },
        cargandoTerminado(){
            return  !this.cargandoComparativos &&
                    !this.cargandoMetricas &&
                    (this.$refs.grafica && !this.$refs.grafica.loading1) &&
                    (this.$refs.grafica && !this.$refs.grafica.loading2) &&
                    (this.$refs.grafica && !this.$refs.grafica.loading3) &&
                    (this.$refs.grafica && !this.$refs.grafica.loading4)
        }
    },
    watch: {
        rango(val){
            switch (val){
            case 1:
                this.fecha = []
                this.fecha[0] = new Date()
                this.fecha[1] = new Date()
                this.disable_fecha = true
                break;
            case 2:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'day').toDate()
                this.fecha[1] = moment().subtract(1, 'day').toDate()
                this.disable_fecha = true
                break;
            case 3:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'week').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = true
                break;
            case 4:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'week').startOf('week').toDate()
                this.fecha[1] = moment().subtract(1, 'week').endOf('week').toDate()
                this.disable_fecha = true
                break;
            case 5:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'month').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = true
                break;
            case 6:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'month').startOf('month').toDate()
                this.fecha[1] = moment().subtract(1, 'month').endOf('month').toDate()
                this.disable_fecha = true
                break;
            case 7:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'year').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = true
                break;
            case 8:
                this.fecha = []
                this.fecha[0] = moment().startOf('month').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = false
                break;

            default:
                break;
            }
        },
        tipo(val){
            this.montarSemanaHoras()
        },
        // id_cedis(val){
        //     if(this.primeraVez) return
        //     this.montar()
        // },
        id_moneda(val,oldVal){
            if(oldVal == null) return
            if(this.primeraVez) return
            this.$refs.grafica.cargando()
            this.cargando()
            this.montar()
        },
        filtro_relacion(){
            this.calcularComparativos()
        },
        tipo_fecha(){
            this.calcularMetricasInformes()
            this.calcularComparativos()
        },
        cargandoTerminado(val){
            this.$store.commit('cedis/setCargandoTerminado', val)
        }
    },
    mounted(){
        this.$store.commit('cedis/setCargandoTerminado', false)

        this.montar()
    },
    methods: {
        cargando(informes = true){
            if (informes){
                this.cargandoComparativos = true
            }
            this.cargandoMetricas = true
        },
        async montar(){
            try {
                if(this.id_moneda === null){
                    setTimeout(async() => {
                        await this.comparativos()
                        setTimeout( async() => {
                            await this.$refs.grafica.montar()
                        }, 500);
                        await this.metricas_informe()
                    }, 1000);
                }else{
                    await this.comparativos()
                    setTimeout( async() => {
                        await this.$refs.grafica.montar()
                    }, 500);
                    await this.metricas_informe()
                }
            } catch (error){
                console.log(error)
            }finally{
                this.primeraVez = false
            }
        },
        estiloEdad(estado){
            switch(estado){
            case 1:
                return 'text-orange-clear_2'
            case 2:
                return 'text-green'
            case 3:
                return 'text-purple'
            case 4:
                return 'text-orange-clear'
            case 5:
                return 'text-dark-blue'
            case 6:
                return 'text-blue-light'
            case 7:
                return 'text-pink-light'
            case 8:
                return 'text-dark-green'
            case 9:
                return 'text-blue'
            case 10:
                return 'text-dark-pink'
            case 11:
                return 'text-dark'
            }
        },
        backgroundEdad(estado){
            switch(estado){
            case 1:
                return 'bg-orange-clear_2'
            case 2:
                return 'bg-green'
            case 3:
                return 'bg-purple'
            case 4:
                return 'bg-orange-clear'
            case 5:
                return 'bg-dark-blue'
            case 6:
                return 'bg-blue-light'
            case 7:
                return 'bg-pink-light'
            case 8:
                return 'bg-dark-green'
            case 9:
                return 'bg-blue'
            case 10:
                return 'bg-dark-pink'
            case 11:
                return 'bg-dark'
            /* case 10:
                return 'text-green' */
            }
        },
        modalRelacion(idx,ids,titulo){
            if (idx>1 && ids.length > 0){
                let filtro = ''
                switch (this.filtro_relacion){
                case 1:
                    filtro = "Durante última semana"
                    break;
                case 2:
                    filtro = "Durante último mes"
                    break;
                case 3:
                    filtro = "Durante último trimestre"
                    break;
                case 4:
                    filtro = "Durante Hoy"
                    break;
                default:
                    break;
                }
                this.$refs.modalDetalleRelacion.toggle(ids,titulo,filtro)
            }
        },
        AbrirProductos(tipo){
            let params = {
                id_tienda: this.id_tienda,
                fecha_inicio: moment(this.fecha[0]).format('Y-MM-DD'),
                fecha_fin: moment(this.fecha[1]).format('Y-MM-DD'),
                tipo,
                id_moneda: this.moneda_rol,
                id_cedis: this.id_cedis
            }
            this.$refs.productos.toggle(params)
        },
        async metricas_informe(){
            try {
                let params = {
                    id_tienda: this.id_tienda,
                    fecha_inicio: moment(this.fecha[0]).format('Y-MM-DD'),
                    fecha_fin: moment(this.fecha[1]).format('Y-MM-DD'),
                    id_moneda: this.moneda_rol,
                    id_cedis: this.id_cedis
                }
                const { data } = await Metricas.metricas_informe(params)

                this.metricas_created = data.data.pedidos_created
                this.metricas_entregas = data.data.pedidos_entregas

                this.calcularMetricasInformes()
            } catch (error){
                this.error_catch(error)
            }finally{
                this.cargandoMetricas = false
            }
        },
        calcularMetricasInformes(){
            try {

                let datos = this.tipo_fecha == 1 ? this.metricas_entregas : this.metricas_created

                this.comparativas = datos.comparativas

                this.dias = datos.dias
                this.horas = datos.horas
                this.max_dias_cantidad = datos.max_dias_cantidad
                this.min_dias_cantidad = datos.min_dias_cantidad
                this.max_dias_total = datos.max_dias_total
                this.min_dias_total = datos.min_dias_total
                this.max_horas_cantidad = datos.max_horas_cantidad
                this.min_horas_cantidad = datos.min_horas_cantidad
                this.max_horas_total = datos.max_horas_total
                this.min_horas_total = datos.min_horas_total

                this.montarSemanaHoras()

                datos.productos_card_total.map(p=>{
                    p.stock_actual = this.agregarSeparadoresNumero(p.stock_actual)
                })
                this.productos_card_total = datos.productos_card_total
                this.productos_ver_mas_total = datos.productos_ver_mas_total

                datos.productos_card_cantidad.map(p=>{
                    p.stock_actual = this.agregarSeparadoresNumero(p.stock_actual)
                })
                this.productos_card_cantidad = datos.productos_card_cantidad
                this.productos_ver_mas_cantidad = datos.productos_ver_mas_cantidad

                this.chart_distancia.dataset.source = datos.distancias
                this.datos_distancia[0].valor = datos.distancias_totales
                this.datos_distancia[1].valor = datos.distancia_max_totales ? datos.distancia_max_totales.total : 0
                this.datos_distancia[1].fecha = datos.distancia_max_totales ? `${this.agregarSeparadoresNumero(datos.distancia_max_totales.distancia)}m` : 'No registra'
                this.datos_distancia[2].valor = datos.distancias_pedidos
                this.datos_distancia[3].valor = datos.distancia_max_pedidos ? datos.distancia_max_pedidos.cantidad : 0
                this.datos_distancia[3].fecha = datos.distancia_max_pedidos ? `${this.agregarSeparadoresNumero(datos.distancia_max_pedidos.distancia)}m` : 'No registra'

                //generos
                this.generos = datos.generos
                this.dispositivos = datos.dispositivos
                //edades
                this.categorias_dinero = datos.categorias
                this.compradores_edad = datos.edades

                this.porcentajes_primera_linea = datos.porcentajes_primera_linea
                this.porcentajes_segunda_linea = datos.porcentajes_segunda_linea

                let rechazos_local = []
                let cancelacion_tienda_local = []
                let cancelacion_cliente_local = []
                let cancelacion_admin_local = []

                let rechazos = datos.rechazos
                for (var i = 0; i < rechazos.length; i++){
                    rechazos_local.push({
                        value: rechazos[i].cantidad,
                        name: rechazos[i].motivo,
                        porcentaje: rechazos[i].porcentaje,
                        color: color[i],
                        itemStyle: {
                            normal: {
                                borderWidth: 22,
                                borderColor: color[i]
                            }
                        },

                    });
                }
                this.rechazos = rechazos_local

                let cancelacion_tienda = datos.cancelacion_tienda
                for (var i = 0; i < cancelacion_tienda.length; i++){
                    cancelacion_tienda_local.push({
                        value: cancelacion_tienda[i].cantidad,
                        name: cancelacion_tienda[i].motivo,
                        porcentaje: cancelacion_tienda[i].porcentaje,
                        color: color[i],
                        itemStyle: {
                            normal: {
                                borderWidth: 22,
                                borderColor: color[i]
                            }
                        },

                    });
                }
                this.cancelacion_tienda = cancelacion_tienda_local

                let cancelacion_cliente = datos.cancelacion_cliente
                for (var i = 0; i < cancelacion_cliente.length; i++){
                    cancelacion_cliente_local.push({
                        value: cancelacion_cliente[i].cantidad,
                        name: cancelacion_cliente[i].motivo,
                        porcentaje: cancelacion_cliente[i].porcentaje,
                        color: color[i],
                        itemStyle: {
                            normal: {
                                borderWidth: 22,
                                borderColor: color[i]
                            }
                        },

                    });
                }
                this.cancelacion_cliente = cancelacion_cliente_local

                let cancelacion_admin = datos.cancelacion_admin
                for (var i = 0; i < cancelacion_admin.length; i++){
                    cancelacion_admin_local.push({
                        value: cancelacion_admin[i].cantidad,
                        name: cancelacion_admin[i].motivo,
                        porcentaje: cancelacion_admin[i].porcentaje,
                        color: color[i],
                        itemStyle: {
                            normal: {
                                borderWidth: 22,
                                borderColor: color[i]
                            }
                        },

                    });
                }
                this.cancelacion_admin = cancelacion_admin_local

                this.porcentajes_reclamos = datos.porcentajes_reclamos
                let reclamos = datos.reclamos

                for (let index = 0; index < reclamos.length; index++){
                    reclamos[index].color = color[index]
                }
                this.reclamos = reclamos

            } catch (error){
                console.log(error);
            }
        },
        montarSemanaHoras(){
            try {
                if (this.tipo == 1){
                    this.chart_promedio.dataset.source = this.horas
                    this.datos_promedio[0].nombre = 'Hora de mayor venta promedio'
                    this.datos_promedio[0].valor = this.max_horas_total ? this.max_horas_total.total : 0
                    this.datos_promedio[0].fecha = this.max_horas_total ? this.max_horas_total.alias : 'No registra'
                    this.datos_promedio[1].nombre = 'Hora de menor venta promedio'
                    this.datos_promedio[1].valor = this.min_horas_total ? this.min_horas_total.total : 0
                    this.datos_promedio[1].fecha = this.min_horas_total ? this.min_horas_total.alias : 'No registra'
                    this.datos_promedio[2].nombre = 'Hora de mayor # pedidos promedio'
                    this.datos_promedio[2].valor = this.max_horas_cantidad ? this.max_horas_cantidad.cantidad : 0
                    this.datos_promedio[2].fecha = this.max_horas_cantidad ? this.max_horas_cantidad.alias : 'No registra'
                    this.datos_promedio[3].nombre = 'Hora de menor # pedidos promedio'
                    this.datos_promedio[3].valor = this.min_horas_cantidad ? this.min_horas_cantidad.cantidad :  0
                    this.datos_promedio[3].fecha = this.min_horas_cantidad ? this.min_horas_cantidad.alias :  'No registra'
                } else {
                    this.chart_promedio.dataset.source = this.dias
                    this.datos_promedio[0].nombre = 'Día de mayor venta promedio'
                    this.datos_promedio[0].valor = this.max_dias_total ? this.max_dias_total.total : 0
                    this.datos_promedio[0].fecha = this.max_dias_total ? this.max_dias_total.alias : 'No registra'
                    this.datos_promedio[1].nombre = 'Día de menor venta promedio'
                    this.datos_promedio[1].valor = this.min_dias_total ? this.min_dias_total.total : 0
                    this.datos_promedio[1].fecha = this.min_dias_total ? this.min_dias_total.alias : 'No registra'
                    this.datos_promedio[2].nombre = 'Día de mayor # pedidos promedio'
                    this.datos_promedio[2].valor = this.max_dias_cantidad ? this.max_dias_cantidad.cantidad : 0
                    this.datos_promedio[2].fecha = this.max_dias_cantidad ? this.max_dias_cantidad.alias : 'No registra'
                    this.datos_promedio[3].nombre = 'Día de menor # pedidos promedio'
                    this.datos_promedio[3].valor = this.min_dias_cantidad ? this.min_dias_cantidad.cantidad : 0
                    this.datos_promedio[3].fecha = this.min_dias_cantidad ? this.min_dias_cantidad.alias : 'No registra'
                }
            } catch (error){
                console.log(error);
            }
        },
        async comparativos(){
            try {
                let params = {
                    id_tienda: this.id_tienda,
                    id_moneda: this.moneda_rol,
                    id_cedis: this.id_cedis
                }
                const { data } = await Metricas.comparativos(params)
                this.$validar(data)

                this.semana_created = data.data.semana.pedidos_created
                this.ultimo_mes_created = data.data.ultimo_mes.pedidos_created
                this.hoy_created = data.data.hoy.pedidos_created
                this.semana_entregas = data.data.semana.pedidos_entregas
                this.ultimo_mes_entregas = data.data.ultimo_mes.pedidos_entregas
                this.hoy_entregas = data.data.hoy.pedidos_entregas

                this.calcularComparativos()

            } catch (error){
                this.error_catch(error)
            }finally{
                this.cargandoComparativos = false
            }
        },
        calcularComparativos(){
            try {

                this.semana = this.tipo_fecha == 1 ? this.semana_entregas : this.semana_created
                this.ultimo_mes = this.tipo_fecha == 1 ? this.ultimo_mes_entregas : this.ultimo_mes_created
                this.hoy = this.tipo_fecha == 1 ? this.hoy_entregas : this.hoy_created

                let tipo = {}
                switch (this.filtro_relacion){
                case 1:
                    tipo = this.semana
                    break;
                case 2:
                    tipo = this.ultimo_mes
                    break;
                case 4:
                    tipo = this.hoy
                    break;
                default:
                    break;
                }

                this.relaciones[0].porcentaje = tipo.pedidos.porcentaje
                this.relaciones[0].valor = tipo.pedidos.valor
                this.relaciones[1].porcentaje = tipo.ventas.porcentaje
                this.relaciones[1].valor = tipo.ventas.valor
                this.relaciones[2].porcentaje = tipo.promedio.porcentaje
                this.relaciones[2].valor = tipo.promedio.valor
                this.relaciones[3].porcentaje = tipo.tenderos.porcentaje
                this.relaciones[3].nombre = `Total ${this.$config.vendedor}`
                this.relaciones[3].valor = tipo.tenderos.valor
                this.relaciones[3].ids = tipo.tenderos.id
                this.relaciones[4].nombre = `${this.$config.vendedor} nuevos`
                this.relaciones[4].porcentaje = tipo.nuevos.porcentaje
                this.relaciones[4].valor = tipo.nuevos.valor
                this.relaciones[4].ids = tipo.nuevos.id
                this.relaciones[5].nombre = `${this.$config.vendedor} recurrentes`
                this.relaciones[5].porcentaje = tipo.recurrentes.porcentaje
                this.relaciones[5].valor = tipo.recurrentes.valor
                this.relaciones[5].ids = tipo.recurrentes.id
                this.relaciones[6].nombre = `${this.$config.vendedor} perdidos`
                this.relaciones[6].porcentaje = tipo.perdidos.porcentaje
                this.relaciones[6].valor = tipo.perdidos.valor
                this.relaciones[6].ids = tipo.perdidos.id

            } catch (error){

            }
        },
        async informes(){
            try {
                this.tipo = 1
                this.cargando(false)

                await this.metricas_informe()
            } catch (error){
                console.log(error);
            }
        },
        verCategorias(titulo){
            this.$refs.modalVerCategorias.toggle({ categorias: this.categorias_dinero, titulo});
        },
    }
}
</script>
<style lang="scss" >
.text-blue{
    color: #0892DD;
}
.bg-blue{
    background-color: #0892DD;
}
.br-12{
    border-radius: 12px;
}
.border-bottom-filtro{
        border-bottom: 4px solid #FF9D32;
        border-radius: 1px;
    }
.bg-orange{
    background-color: #FF9539;
}
.text-orange{
    color: #FF9539;
}
.bg-orange-clear{
    background-color: #FFDF75;
}
.bg-blue-clear{
    background-color: #03D6BC;
}
.bg-blue-dark{
    background-color: #284367;
}
.text-blue-clear{
    color: #03D6BC;
}
.bg-purple{
    background-color: #6D3CE1;
}
.text-purple{
    color: #6D3CE1;
}
.sin_datos{
    width:115px;
    height:115px;
    background-color:#f3f3f3;
}
.bg-icon{
    width: 28px;
    height: 28px;
    background: #000000a3;
}
.text-orange-clear_2{ color: #FFB87A;}
.text-orange-clear{ color: #FFDF75; }
.text-green{ color: #02D6BC;}
.text-dark-blue{ color: #284367;}
.text-blue-light{ color: #0892DD ;}
.text-pink-light{ color:#FFB1D0; }
.text-dark-green{ color: #03BB83;}
.text-blue{ color: #6BD1FF;}
.text-dark-pink{ color:#E33FC3;}

.bg-orange-clear_2{ background-color: #FFB87A;}
.bg-orange-clear{ background-color: #FFDF75; }
.bg-green{ background-color: #02D6BC;}
.bg-dark-blue{ background-color: #284367;}
.bg-blue-light{ background-color: #0892DD ;}
.bg-pink-light{ background-color:#FFB1D0; }
.bg-dark-green{ background-color: #03BB83;}
.bg-blue{ background-color: #6BD1FF;}
.bg-dark-pink{ background-color:#E33FC3;}


.bg-blue-clear{background-color: #03D6BC;}
.bg-blue-dark{ background-color: #284367;}
.bg-dark{ background-color: #333435;}
.text-dark{ color: #333435;}
.text-blue-clear{ color: #03D6BC; }
.bg-purple{
    background-color: #6D3CE1;
}
.text-purple{
    color: #6D3CE1;
}
.sin_datos{
    width:115px;
    height:115px;
    background-color:#f3f3f3;
}
.height-calc{
    height:calc(100vh - 180px);
}
.height-leechero{
    height:calc(100vh - 188px);
}
.height-tendero{
    height:calc(100vh - 65px);
}
.card-cantidades{
    width: 225px;
}
/* Media Queries */

@media only screen  and (max-width : 1224px) {
    .card-cantidades{
        .title{
            min-width: 150px;
        }
    }
}

@media (min-width : 320px) and (max-width : 480px) {
    .container-generos{
        .mujer .border{
            width: 100% !important;
        }
        .hombre .border{
            width: 100% !important;
        }
        .otro .border{
            width: 100% !important;
        }
    }
    .container-dispositivo{
        .android .border{
            width: 100% !important;
        }
        .web .border{
            width: 100% !important;
        }
        .ios .border{
            width: 100% !important;
        }
    }
}
</style>
